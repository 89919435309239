@import "~bootswatch/dist/zephyr/_variables.scss";

$breadcrumb-divider: none;

@import "~bootstrap/scss/bootstrap.scss";
@import "~bootswatch/dist/zephyr/_bootswatch.scss";

body {
  background: #fff;
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 13px;
  font-weight: 400;
}

.big-shadow {
  box-shadow: 0 .1rem 1rem .25rem rgba(0, 0, 0, .05) !important;
}

.separator {
  display: block;
  height: 0;
  border-bottom: 1px solid #eff2f5;
}

.separator-vertical {
  height: 20px;
  border-left: 1px solid #eff2f5;
}

.bullet {
  display: inline-block;
  background-color: #b5b5c3;
  border-radius: 6px;
  flex-shrink: 0;
}

.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: #f1416c;
  padding-left: .25rem;
  font-weight: 700;
}

.card {
  .card-header {
    background-color: transparent;
  }
}

.text-muted {
  color: #a1a5b7 !important;
}

.table {
  box-shadow: none;

  thead th {
    text-transform: none;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: .95rem;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;

  .breadcrumb-item {
    display: flex;
    align-items: center;
    padding: 0 .5rem 0 0;
  }
}