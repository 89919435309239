.nav-link.active, .nav-link:hover {
  color: #fff !important;
  background-color: #1b1b28;
}

.nav-link {
  display: flex;
  align-items: center;
  color: #9899ac !important;
  padding: .75rem 25px !important;
}

.menu-item {
  display: block;
  padding: 0;

  .menu-content {
    padding: .65rem 1rem;
  }

  .menu-section {
    font-size: .85rem;
    letter-spacing: .1rem;
    color: #4c4e6f;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.separator {
  border-bottom-color: #282a3d;
}
